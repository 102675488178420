@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* VARIABLES GENERALES */

:root {
    /* Primary Colors */
    --primary-color: #b4903a;
    --primary-color-light: #e9c068;
    --primary-color-dark: #816306;
    /* Secondary Colors */
    --secondary-color: #001631;
    --secondary-color-light: #2c3c5b;
    --secondary-color-dark: #00000b;
    /* Text Colors */
    --text-title: #b4903a;
    --text-color: #37474f;
    --text-light: #c7c7c7;
    --grey-light: #f2ecec;
    /* Fonts */
    --primary-font:  'Philosopher', sans-serif;
    --secondary-font: 'Outfit', sans-serif;
}


/* CLASES PLANTILLA */

.primary-txt {
    color: var(--primary-color) !important;
}

.primary-bg {
    background-color: var(--primary-color) !important;
}

.primary-txt-light {
    color: var(--primary-color-light) !important;
}

.primary-bg-light {
    background-color: var(--primary-color-light) !important;
}

.primary-txt-dark {
    color: var(--primary-color-dark) !important;
}

.primary-bg-dark {
    background-color: var(--primary-color-dark) !important;
}

.secondary-txt {
    color: var(--secondary-color) !important;
}

.secondary-bg {
    background-color: var(--secondary-color) !important;
}

.secondary-txt-light {
    color: var(--secondary-color-light) !important;
}

.secondary-bg-light {
    background-color: var(--secondary-color-light) !important;
}

.secondary-txt-dark {
    color: var(--secondary-color-dark) !important;
}

.secondary-bg-dark {
    background-color: var(--secondary-color-dark) !important;
}

.gray-bg {
    background-color: var(--grey-light);
}

.light-txt {
    color: var(--text-light) !important;
}

.title-txt {
    color: var(--text-title) !important;
}

.color-txt {
    color: var(--text-color) !important;
}


/* TERMINA VARIABLES GENERALES */

h1 {
    font-family: var(--primary-font)  !important;
    font-weight: 600;
    font-size: 5.75rem;
    letter-spacing: -1.5px;
    filter: drop-shadow(black 0px 10px 16px)
}

h2 {
    font-family: var(--primary-font)  !important;
 
    font-size: 4.25rem;
    letter-spacing: -0.5px;
}

h3 {
    font-family: var(--primary-font)  !important;
    font-weight: normal !important;
    font-size: 3.375rem;

}

h4 {
    font-family: var(--primary-font)  !important;
    font-weight: normal !important;
    font-size: 2.375rem;
    letter-spacing: 0.25px;

}

h5 {
    font-family: var(--primary-font) !important;
    font-weight: normal !important;
    font-size: 1.688rem;

}

h6 {
    font-family: var(--primary-font)  !important;
    font-weight: 500;
    font-size: 1.438rem;
    letter-spacing: 0.15px;

}

.subtitle{
    font-family: var(--primary-font)  !important;
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.15px;
    line-height: 140%; 
    padding:0px;margin:0px;
}

.body-uno, p{
    font-family: var(--secondary-font)  !important;
    font-weight: medium;
    letter-spacing: 0.5px;

}

.btn {
    font-family: var(--secondary-font) !important;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.15px;
    line-height: 140%; 
}

.caption{
    font-family: var(--secondary-font)  !important;
    font-weight: 500;
    font-size: 0.813rem;
    letter-spacing: 0.4px;
    line-height: 140%;   
}

.overline{
    font-family: var(--secondary-font)  !important;
    font-weight: 500;
    font-size: 0.688rem;
    letter-spacing: 1.5px;
    line-height: 140%;   
}

/*  RUNA FOTOS  */
.runa-title-left{
    color: #ffffff36;
    font-size: 10rem;
    position: absolute;
    top: -80px;
    z-index: 20;
}

.runa-title-right{
    color: #ffffff36;
    font-size: 10rem;
    position: absolute;
    top: -80px;
    right: 0px;
    text-align: right;
    z-index: 20;
}

/*  RUNA FOTOS  */

/* Formulario runa  */
.runa-form{
    border-color: transparent;
    border-width: 1px;
    border-style: solid;
    padding: 35px 40px;
    background: linear-gradient(180deg, #100F46 0%, rgba(16, 15, 70, 0) 100%);
    filter: drop-shadow(0px 18px 18px rgba(0, 0, 0, 0.5));
    backdrop-filter: blur(61px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 16px;
}



.runa-form textarea, .runa-form input{
    background-color: #00163187;
    border-color: #00163187;
    color: white;
}
.runa-form textarea:focus, .runa-form input:focus{
    background-color: #02265287;
    border-color: #00163187;
    color: white;
}
/* Formulario runa  */

.banner-top{
    background-image: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height:100vh;
}

.banner-brochure{
    background-image: url('../img/crystal.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius:24px;
}

.banner-brochure p{
    font-size:2rem;
    font-weight:500;
    text-shadow: black 10 10 10;
}

.aboutus{
    background-image: url('../img/aboutus.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.amenities{
    background-image: url('../img/aboutus.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 0 200px 200px;
    
}

.rounded-6{
    border-radius:24px;
}

.shadow{
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.25));
}

.gris{
    background: #E5E5E5;  
}

.zprincipal{
    z-index: 99;
}

.degradadobottom{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 99.99%, #000000 100%);
    z-index: 1;
    height: 380px;
    bottom:0px;
    position: absolute;
}

.spacialglow{
    -webkit-box-shadow: 0px 0px 190px -37px rgba(126 0 114);
-moz-box-shadow: 0px 0px 190px -37px rgba(126 0 114);
box-shadow: 0px 0px 190px -37px rgba(126 0 114);
width: 100%;height: 100%;
}

.degradadotop{
    background: linear-gradient(180deg, #000000 0%, #000000 0.01%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
    height: 615px;
    top:0px;
    position: absolute;
}

.gradiente-gris{
    background: linear-gradient(360deg, #E9EFFF 0%, rgba(196, 196, 196, 0) 100%);   
}

.gradiente-gris-down{
    background: linear-gradient(180deg, #E9EFFF 0%, rgba(196, 196, 196, 0) 100%);
}



.letras-runicas{
    background-image: url('../img/letras.png');
    background-position: left bottom;
    background-size: cover;
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
}

.carousel-control-next, .carousel-control-prev{
    height: 295px;
}
.wood1{
    position: absolute;
    z-index: 0;
    overflow: hidden;
    width: 270px;
    height: auto;
    top: 0;
    left: 0px;
}

.wood2{
    position: absolute;
    z-index: 20;
    overflow: hidden;
    width: 550px;
    height: auto;
    bottom: 50px;
    right: 0px;
}

.wood3{
    position: absolute;
    z-index: 0;
    overflow: hidden;
    width: 550px;
    height: auto;
    bottom: 0px;
    left: -150px;
}

.wood4{
    position: absolute;
    z-index: 20;
    overflow:hidden;
    width: 450px;
    height: auto;
    top: 0px;
    right: 0px;
}


.leaf1{
    position: absolute;
    z-index: 0;
    overflow: hidden;
    width: 320px;
    height: auto;
    top: 0;
    right: 0px;
}

.leaf2{
    position: absolute;
    z-index: 0;
    overflow: hidden;
    width: 350px;
    height: auto;
    bottom: 0;
    left: -10px;
}

.gold1{
    overflow: hidden;
    position: absolute;
    right: 0px;
    height: 113px;
    width: auto;
    z-index: 0;
    margin:0px;
    padding:0px;


}

.gold-middle{
    overflow: hidden;
    position: absolute;
    top:-40px;
    height: 95px;
    width: auto;
    z-index: 0;
    margin:0px;
    padding:0px;

}

.runas{
    width:386px;
    height: 600px;
    position: absolute;
    top:-80px;
    right:0px;
    overflow: hidden;
    z-index: 9999;
}



#financiamiento{

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
   
}

.runas-financiamiento{
    border: 4px solid #C48C2D;
}

 .circle{
    background-image: url("../img/financial-circle.svg");
    background-size: 300px 300px;
    background-repeat: no-repeat;
    background-position: center center;
    height: 300px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.masterplan{
    background-image: url("../img/mp-marco.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size:contain;

}

.woodBrush{
    position: absolute;
    z-index: 0;
    overflow: hidden;
    width: 550px;
    height: auto;
    bottom: 0;
    left: -10px;
}

.map{
    z-index: 99 !important;
}
.map iframe{ height:800px}
.nosotros{
    position: absolute;
    z-index: 0;
    overflow: hidden;
    width: 450px;
    height: auto;
    bottom: 50;
    right: 0px;

}

.card-img-overlay .h5{
    bottom:50px !important;
}

.nosotros-circle{
    z-index: 99;
    overflow: hidden;
}

.degradado{
    background: rgb(0,31,68);
background: linear-gradient(180deg, rgba(0,31,68,1) 47%, rgba(0,0,0,1) 100%);
}

.degradado-asesores{background: linear-gradient(180deg, rgba(20, 20, 154, 0) 54.17%, #000466 100%);}

.runa-privacy{
    background-image: url("../img/runa-privacy.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size:cover;
}

.nav-runa{
    background: linear-gradient(180deg, rgba(16, 15, 70, 0.82) 0%, rgba(13, 12, 60, 0.1804) 100%);
    backdrop-filter: blur(12px);
    /* Note: backdrop-filter has minimal browser support 
    
    border-radius: 12px;  */
}

/* Custom Scroll Navigator Bar */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
   }
   ::-webkit-scrollbar-thumb {
    background: linear-gradient(13deg, #170f4f 14%,#1d004c 64%);
    border-radius: 10px;
   }
   ::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(13deg, #004C3F 14%,#408686 64%);
   }
   ::-webkit-scrollbar-track{
    background: var(--grey-light);
    border-radius: 0px;
    box-shadow: inset 7px 10px 12px var(--secondary-color);
   }



  /* SELECCION DE TEXTO */
  ::selection {
    background: var(--primary-color);
    color: white;
   
}

/* Firefox */
::-moz-selection {
    background: var(--primary-color);
    color: white;  
}
/* SELECCION DE TEXTO */


    /*RESPONSIVE*/
    
    @media all and (min-width:1024px) and (max-width:1439px) {
        .banner-top{
            background-position: 80% 0%;
            background-size: cover !important;background-repeat: no-repeat;
            background-image: url("../img/banner.jpg");
            background-color: black;
           
        }
        h1 {    
        font-size: 2.75rem;
    }


        .gold1{

            height: 80px;
            width: auto;
            margin-top:80px;
        
        
        }
        .runas{
            width:326px;
            height: 530px;
            position: absolute;
            top:0px;
            right:0px;
            overflow: hidden;
            z-index: 9999;
        }

        .amenities{
            border-radius: 0 0 130px 130px;
        }
    }
    
    @media all and (min-width:768px) and (max-width:1023px) 
    {
        .banner-top{
            background-position: bottom right;
            background-size: cover !important;background-repeat: no-repeat;
            background-image: url("../img/bannermobile.jpg");
            background-color: black;
           
        }

        h1 {
        font-size: 2.75rem;}
        .gold1{
            height: 60px;
            width: auto;
            margin-top:110px;
        }

        .amenities{
            border-radius: 0 0 130px 130px;
        }



    }
    
    @media all and (min-width:426px) and (max-width:767px) {
        h1 {
            font-size: 1.75rem;
        }


    }
    
    @media all and (max-width: 425px) {
        .navbar-dark .navbar-toggler, .navbar-dark .navbar-toggler, .navbar-dark .navbar-toggler, .navbar-toggler:focus{
            border-color:transparent !important;
        }

        h1 {
            font-family: var(--primary-font)  !important;
            font-weight: 600;
            font-size: 2rem;
            letter-spacing: -1.5px;  
            filter: drop-shadow(black 0px 10px 16px)
        }

        h2{
            font-weight: 600;
            font-size: 1.5rem !important;
        }
        h3{
            font-weight: 600;
            font-size: 1.45rem !important;
        }
        h4{
            font-weight: 600;
            font-size: 1.3rem !important;
        }

        h4{
            font-weight: 600;
            font-size: 1.3rem !important;
        }

        h6{
            font-weight: 500;
            font-size: 1rem !important;
        }

        .rounded-6{
            border-radius:16px;
        }
    
        .gold-middle{
            height: 45px;
            width: 200px;
            top:-15px;
        }
        .banner-top{
            height:100%;
            background-position: top center;
            background-size: contain !important;background-repeat: no-repeat;
            background-image: url("../img/bannermobile.jpg");
            background-color: black;
           
        }
        .runa-form{
            margin-top:480px;
        }


        .amenities{
            border-radius: 0 0 50px 50px;
        }
        
        .nosotros{
            width: 100%;
            bottom:0px;
        }

        #nosotros{
            height: auto;
        }

        .runas{
            width:210px;
            height: 315px;
            position: absolute;
            top:-80px;
            right:0px;
            overflow: hidden;
            z-index: 9999;
        }

        .gold1{
            width:148px;
            height: 30px;
            left: 25%;
            right: 25%;

        }
        
         /*  RUNA FOTOS  */
        .runa-title-left{
            color: #ffffff36;
            font-size: 4rem;
            position: absolute;
            top: -30px;
            z-index: 20;
        }

        .runa-title-right{
            color: #ffffff36;
            font-size: 4rem;
            position: absolute;
            top: -30px;
            right: 0px;
            text-align: right;
            z-index: 20;
        }
        
        .wood1{
            z-index: 20; 
            width: 120px !important;
        }
        .wood2{
            width: 250px !important;
            bottom: 50px;
        }

        .wood3{
            bottom:0px;
            z-index: 20; 
            width: 120px !important;
        }
        .wood4{
            width: 200px !important;
            top: 130px;
        }
        .woodBrush{
            top:250px;
            width: 250px;
        }

        .map iframe{ height:400px}
  
    }
    /* RESPONSIVE*/